import React, { forwardRef, useEffect, useState } from 'react';

import { Popper } from 'components/popper/popper';
import { getInputVariant } from 'constants/formElements';
import { mergeRefs } from 'utils/fns';
import { formatNumberAsString, formatPercentage } from 'utils/normalizer';
import { usePrevious } from 'utils/use-previous';

import { InputPlaceholder } from '../input-placeholder';
import { Input, InputWrapper } from '../styles';

/**
 * @deprecated use PercentageField from components/forms instead
 */
export const PercentageInputComponent = forwardRef(
    (
        {
            onChange,
            value,
            hidePlaceholder,
            integer = false,
            gridArea,
            className,
            max = 100,
            min = 0,
            required,
            round = true,
            error,
            ...rest
        }: any,

        ref: any
    ) => {
        const [myValue, setMyValue] = useState(+value);
        const prevMyValue = usePrevious(myValue);
        const [isPopperOpen, setIsPopperOpen] = useState(false);
        const [referenceElement, setReferenceElement] = useState(null);

        const [innerRefElement, setInnerRefElement] = useState(null);

        useEffect(() => {
            if (error && document.activeElement === innerRefElement) {
                // if it has focus we show the error
                setIsPopperOpen(true);
            }
        }, [innerRefElement, error]);

        useEffect(() => {
            const isOutOfRangeMin = +value < min;
            const isOutOfRangeMax = +value > max;
            if (isOutOfRangeMin || isOutOfRangeMax) {
                setMyValue(prevMyValue);
                return;
            }

            setMyValue(value);
        }, [value]);

        return (
            <InputWrapper
                className={className}
                css={{ gridArea }}
                ref={setReferenceElement}
            >
                <Input
                    ref={mergeRefs(ref, setInnerRefElement)}
                    type="text"
                    id={rest?.id || rest.name}
                    error={error}
                    variant={getInputVariant(error)}
                    {...rest}
                    onChange={(e) => {
                        const fieldVal = e.currentTarget.value;
                        e.currentTarget.value = formatNumberAsString(
                            fieldVal,
                            integer
                        );
                        onChange && onChange(e);
                    }}
                    value={formatPercentage(myValue, round)}
                    css={{ height: hidePlaceholder ? 40 : undefined }}
                    onFocus={() => error && setIsPopperOpen(true)}
                    onBlur={() => setIsPopperOpen(false)}
                />
                {error?.message && (
                    <Popper
                        referenceElement={referenceElement}
                        isOpen={isPopperOpen}
                        variant="error"
                    >
                        {error.message}
                    </Popper>
                )}

                {!hidePlaceholder && (
                    <InputPlaceholder
                        name={rest.name}
                        placeholder={rest.placeholder}
                        hidePlaceholder={hidePlaceholder}
                        required={required}
                    />
                )}
            </InputWrapper>
        );
    }
);
