import { ErrorMessage as FormErrorMessage } from '@hookform/error-message';
import {
    CurrencyInput,
    CurrencyInputProps,
    ErrorMessage,
    FormElement,
    FormElementProps,
    HelperText,
} from '@nestoca/ui';
import { Controller, useFormContext } from 'react-hook-form';

import { InputFieldProps } from './input-field';
import { trimDecimals } from './utils';

export const CurrencyField = ({
    name,
    handleErrorMessage = true,
    helperText,
    isDisabled,
    isRequired,
    onChange,
    ...rest
}: CurrencyInputProps & InputFieldProps & FormElementProps) => {
    const {
        formState: { errors },
        control,
    } = useFormContext();

    return (
        <FormElement
            isInvalid={!!errors?.[name]}
            isDisabled={isDisabled}
            isRequired={isRequired}
        >
            <Controller
                control={control}
                name={name}
                rules={{
                    pattern: /[^0-9.]/g,
                }}
                render={({ field }) => (
                    <CurrencyInput
                        id={name}
                        e2ePrefix={name}
                        {...rest}
                        {...field}
                        onChange={(e) => {
                            const trimmedValue = trimDecimals(e.target.value);

                            field.onChange(trimmedValue);
                            onChange && onChange(e);
                        }}
                    />
                )}
            />

            {handleErrorMessage && (
                <FormErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => (
                        <ErrorMessage withIcon e2ePrefix={`${name}`}>
                            {message}
                        </ErrorMessage>
                    )}
                />
            )}

            {helperText && <HelperText>{helperText}</HelperText>}
        </FormElement>
    );
};
